exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-activity-tsx": () => import("./../../../src/pages/activity.tsx" /* webpackChunkName: "component---src-pages-activity-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-introduce-tsx": () => import("./../../../src/pages/introduce.tsx" /* webpackChunkName: "component---src-pages-introduce-tsx" */),
  "component---src-pages-privacy-policy-tsx": () => import("./../../../src/pages/privacy/policy.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-tsx" */),
  "component---src-pages-terms-tsx": () => import("./../../../src/pages/terms.tsx" /* webpackChunkName: "component---src-pages-terms-tsx" */),
  "component---src-templates-entry-template-tsx": () => import("./../../../src/templates/entryTemplate.tsx" /* webpackChunkName: "component---src-templates-entry-template-tsx" */),
  "component---src-templates-tag-template-tsx": () => import("./../../../src/templates/tagTemplate.tsx" /* webpackChunkName: "component---src-templates-tag-template-tsx" */)
}

